<template>
  <div class="eden-invoice">
    <div class="eden-invoice--body">
      <div class="eden-invoice--body__header">
        <img :src="getImage('eden_logo.svg')" alt="Eden" />
      </div>
      <div class="eden-invoice--body__row">
        <div>
          <span>Issue date</span>
          <div>
            <p>{{ formatDate(data.date_of_issue, "do m, y") }}</p>
          </div>
        </div>
        <div>
          <span>Due date</span>
          <div>
            <p>{{ formatDate(data.due_date, "do m, y") }}</p>
          </div>
        </div>
      </div>
      <div class="eden-invoice--body__row">
        <div>
          <span>Bill to</span>
          <div class="eden-invoice--address">
            <p>{{ data.company_name }}</p>
            <p>{{ data.company_address }}</p>
          </div>
        </div>
        <div>
          <span>Bill from</span>
          <div class="eden-invoice--address">
            <p>Eden Life Inc.</p>
            <p v-if="location === 'KE'">
              Unit 6, Chameleon Avenue, Westlands, Nairobi
            </p>
            <p v-else>Jacob Mews Estate, Adebisi Street, Yaba, Lagos.</p>
          </div>
        </div>
      </div>
      <div class="eden-invoice--body__row">
        <div>
          <span>Number of employees</span>
          <div>
            <p>{{ data.number_of_active_employees }}</p>
          </div>
        </div>
      </div>
      <div class="eden-invoice--body__details">
        <div class="table">
          <table>
            <tr>
              <th>Service</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
            <template v-if="plan">
              <tr v-for="(value, key, i) in plan" :key="i">
                <td>
                  <div>
                    <p>
                      <span class="text-grey-primary text-bold">{{
                        formatText(key)
                      }}</span>
                      ({{ formatText(frequencyLabel(value.frequency)) }})
                    </p>
                    <p v-if="value.item">
                      {{ formatToTitleCase(value.item) }}
                    </p>
                  </div>
                </td>
                <td>
                  {{ formatPrice(value.amount) }}
                </td>
                <td>
                  {{ formatPrice(value.amount) }}
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
      <div class="eden-invoice--body__summary">
        <div class="table">
          <table>
            <tr v-for="(item, i) in summary" :key="i">
              <td></td>
              <td :class="{ secondToLast: i === summary.length - 2 }">
                {{ item.label }}
              </td>
              <td :class="{ secondToLast: i === summary.length - 2 }">
                {{ formatPrice(item.value) }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="eden-invoice--footer">
      <p class="text-bold mb-1">Bank Account Information</p>
      <p>Stanbic IBTC Bank PLC</p>
      <p>0033675922</p>
      <p>Eden Life Concierge Limited</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomerCorporateInvoice",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    plan() {
      return this.data.plan ? JSON.parse(this.data.plan) : {};
    },
    summary() {
      return [
        {
          label: "Subtotal",
          value: this.data.total_amount,
        },
        {
          label: "Amount owed due to pauses",
          value: this.data.outstanding_amount,
        },
        {
          label: `Withholding tax value (${this.data.witholding_tax_value_percent}%)`,
          value: this.data.witholding_tax_value,
        },
        {
          label: `Discount ${
            this.data.discount_type === "percentage"
              ? `(${this.data.discount_value}%)`
              : ""
          }`,
          value: this.data.discount_amount,
        },
        {
          label: "Amount to pay",
          value: this.data.amount_to_be_charged,
        },
      ];
    },
    location() {
      return this.$store.getters.location;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.eden-invoice {
  width: 90%;
  border: 1px solid #e4e8e6;
  margin: 40px auto;
  box-sizing: border-box;
  border-radius: 8px;

  &--body {
    padding: 40px 40px 40px;

    &__header {
      margin-bottom: 40px;

      img {
        height: 50px;
      }
    }

    &__row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      > div {
        span {
          color: #798b83;
          font-size: 0.875rem;
          display: block;
          margin-bottom: 7px;
        }

        p {
          font-size: 0.875rem;
          color: #21312a;
          line-height: 1.8;
          width: 200px;
          word-break: break-word;
        }
      }
    }

    &__details {
      .table {
        border: 1px solid rgba(228, 232, 230, 0.4);
        border-radius: 4px;
        padding: 10px 0;

        tr:last-child {
          td {
            border: none;
            padding: 22px;
          }
        }
      }
    }

    &__summary {
      margin-top: 20px;

      .table {
        td {
          padding-top: 8px;
          padding-bottom: 8px;

          &.secondToLast {
            padding-bottom: 24px;
          }

          &:last-child {
            padding-right: 0 !important;
          }
        }

        tr:last-child {
          td {
            border-top: 1px solid #c9d0cd;
            padding-top: 24px;
            color: #03a84e;
            font-size: 1rem;
            font-weight: 600;

            &:first-child {
              border: none;
            }
          }
        }
      }
    }
  }

  &--footer {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    background: #21312a;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      color: #ffffff;
      line-height: 1.8;
    }
  }
}

table {
  border: none;
  border-collapse: collapse;
  width: 100%;
}

th {
  font-weight: 400;
  border: none !important;
  font-size: 0.875rem;
  color: #57645e;
  text-align: left;
  padding: 14px 20px;

  &:nth-child(2) {
    padding-left: 0 !important;
  }

  &:last-child {
    text-align: right;
  }
}

td {
  border: none;
  text-align: left;
  color: #21312a;
  padding: 14px 20px;
  font-size: 0.875rem;

  &:first-child {
    width: 40%;
  }

  &:nth-child(2) {
    padding-left: 0 !important;
  }

  &:last-child {
    text-align: right;
  }
}
</style>
