import axios from "axios";

export default {
  index(params) {
    return axios.get(`/corporate_wallet/transactions?${params}`);
  },
  
  summary(id) {
    return axios.get(`/corporate_wallet/summary/${id}`);
  },

  fundWallet(payload) {
    return axios.post(`/corporate_wallet/fund`, payload);
  },

  fundWithLink(payload) {
    return axios.post(`/payment/links/corporate`, payload);
  },
  
  topUp(payload) {
    return axios.post(`/corporate_wallet/employees/topup`, payload);
  },

  export(params) {
    return axios.get(`/corporate_wallet/transactions/all?${params}`);
  },
};
