<template>
  <el-dialog
    title="Renew Subscription"
    :visible.sync="shouldShow"
    @close="closeEvent"
    append-to-body
    width="30%"
  >
    <div v-loading="loading">
      <el-form :model="form" label-position="top" ref="form">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Subscription amount"
              prop="subscription_amount"
              :rules="validateField()"
            >
              <el-input
                v-model.number="data.subscription_amount"
                v-number
                readonly
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Total number of pauses"
              prop="total_pauses"
              :rules="validateField()"
            >
              <el-input
                v-model.number="form.total_pauses"
                v-number
                @change="updateOutstandingAmount"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Outstanding amount"
              prop="outstanding_amount"
              :rules="validateField()"
            >
              <el-input
                v-model.number="form.outstanding_amount"
                v-number
                placeholder="Enter amount"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item
              label="Total new  amount"
              prop="total_new_amount"
              :rules="validateField()"
            >
              <el-input
                v-model.number="form.total_new_amount"
                v-number
                placeholder="Enter amount"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" :gutter="10" class="flex-wrap">
          <el-col :span="24">
            <el-form-item
              label="Start of new subscription"
              prop="start_date"
              :rules="validateField()"
            >
              <el-date-picker
                v-model="form.start_date"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd MMM, yyyy"
                :picker-options="{
                  disabledDate: disabledDates
                }"
                prefix-icon="eden-icon--calendar"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button type="primary" :loading="renewing" @click="renew"
          >Renew</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateSubscriptionRenewal",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        subscription_amount: 0,
        total_pauses: 0,
        outstanding_amount: 0,
        total_new_amount: 0,
        start_date: "",
      },
      renewing: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.setForm();
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.$refs.form.resetFields();
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin", "admin"])) {
        return false;
      }
      return time.getTime() < new Date().getTime();
    },
    setForm() {
      this.loading = true;
      const unitPrice = this.pricing(this.data.plan);
      const activeEmployees = this.data.active_employees_count;
      this.form.subscription_amount = unitPrice * activeEmployees;
      this.getNumberOfPausedServices();
    },
    getNumberOfPausedServices() {
      const payload = {
        user_corporation_id: parseInt(this.customerId),
      };
      corporate
        .getPausedServices(payload)
        .then((response) => {
          if (response.data.status) {
            this.form.total_pauses = response.data.data;
            this.form.outstanding_amount =
              this.getMealPrice() * this.form.total_pauses;
            this.form.total_new_amount =
              this.data.subscription_amount - this.form.outstanding_amount;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
    updateOutstandingAmount() {
      this.form.outstanding_amount =
        this.getMealPrice() * this.form.total_pauses;
    },
    getMealPrice() {
      let { meal } = this.data.plan;
      // meal.frequency = "one-off";

      // return this.pricing({ meal });
      let { qty, frequency, service_day, amount } = meal;
      if (!meal) {
        return 0;
      }

      let basePrice;

      if (frequency === "daily") {
        basePrice = amount / (qty * 4);
      } else {
        const delivery = (service_day.length - 1) * 1000 * 4;
        const price = amount - delivery;
        basePrice = price / (qty * 4);
      }
      return basePrice;
    },
    renew() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.renewing = true;
        this.form.outstanding_amount = this.formatInteger(this.form.outstanding_amount)
        this.form.total_new_amount = this.formatInteger(this.form.total_new_amount)
        const { outstanding_amount, total_new_amount, start_date } = this.form;
        const payload = {
          outstanding_amount,
          total_new_amount,
          start_date
        };
        corporate
          .renewSubscription(this.customerId, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.renewing = false;
              this.closeEvent();
            } else {
              this.renewing = false;
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.renewing = false;
            this.$message.error(error.response.data.message);
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-footer {
  margin-top: 20px;
}
</style>
