<template>
  <el-dialog
    title="Pause or Unpause Orders"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <el-row>
      <el-col :span="24">
        <el-radio-group
          v-model="action"
          class="eden-switch-radio small"
          @change="resetForm"
        >
          <el-radio-button label="pause">Pause</el-radio-button>
          <el-radio-button label="unpause">Unpause</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="note">
          <p>
            Note: If you are
            <span
              >{{ action === "pause" ? "pausing" : "unpausing" }} for a
              day</span
            >, do not select an End Date.
          </p>
        </div>
      </el-col>
    </el-row>

    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="24">
          <el-form-item label="Service" prop="service" :rules="validateField()">
            <el-select v-model="form.service" placeholder="Select service">
              <el-option
                v-for="(service, index) in services"
                :key="index"
                :label="`${formatText(service)}`"
                :value="service"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="is-flex-wrap">
        <el-col :span="12">
          <el-form-item
            label="Start date"
            prop="start_date"
            :rules="validateField()"
          >
            <el-date-picker
              v-model="form.start_date"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd MMM, yyyy"
              :picker-options="{
                disabledDate: disabledDates,
              }"
              prefix-icon="eden-icon--calendar"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="End date" prop="end_date">
            <el-date-picker
              v-model="form.end_date"
              type="date"
              value-format="yyyy-MM-dd"
              format="dd MMM, yyyy"
              :picker-options="{
                disabledDate: disableStartDate,
              }"
              :disabled="form.start_date === ''"
              prefix-icon="eden-icon--calendar"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        v-if="action === 'pause'"
        type="primary"
        :loading="updating"
        @click="pauseCustomerOrders"
        >Pause</el-button
      >
      <el-button
        v-else-if="action === 'unpause'"
        type="primary"
        :loading="updating"
        @click="unPauseCustomerOrders"
        >Unpause</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateOrdersPause",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    employees: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      action: "pause",
      updating: false,
      form: {
        service: "",
        start_date: "",
        end_date: "",
      },
      services: [],
      lastServiceDay: null,
    };
  },

  created() {
    this.getServices();
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    userId() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    getServices() {
      const plan =
        typeof this.data.plan === "string"
          ? JSON.parse(this.data.plan)
          : this.data.plan;
      plan ? (this.services = Object.keys(plan)) : [];
      this.lastServiceDay = this.data.next_charge_date;
    },
    disabledDates(time) {
      if (this.allowAccessFor(["superadmin", "gardener_pro"])) {
        return false;
      }

      const yesterday = new Date(
        new Date().getTime() - 2 * 24 * 60 * 60 * 1000,
      );
      const lastServiceDay = new Date(this.lastServiceDay).getTime();
      return time.getTime() < yesterday || time.getTime() > lastServiceDay;
    },
    disableStartDate(time) {
      if (this.allowAccessFor(["superadmin", "gardener_pro"])) {
        return false;
      }

      const lastServiceDay = new Date(this.lastServiceDay).getTime();
      return (
        time.getTime() <= new Date(this.form.start_date).getTime() ||
        time.getTime() > lastServiceDay
      );
    },
    closeEvent() {
      this.shouldShow = false;
      this.resetForm();
    },
    resetForm() {
      this.$refs.form.resetFields();
    },
    payload() {
      const payload = {
        ...this.form,
        corporate_customer_id: this.userId,
        corporate_employees_id: this.employees,
      };

      if (payload.end_date === "") {
        payload.end_date = payload.start_date;
      }

      return payload;
    },
    pauseCustomerOrders() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;
        const payload = this.payload();

        corporate
          .ordersPause(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
              this.updating = false;
            } else {
              this.$message.error(response.data.message);
              this.updating = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.updating = false;
          });
        return true;
      });
    },
    unPauseCustomerOrders() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.updating = true;
        const payload = this.payload();

        corporate
          .ordersUnpause(payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeEvent();
              this.updating = false;
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
            this.updating = false;
          });
        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.note {
  margin: 10px 0;

  span {
    font-weight: bold;
  }
}
</style>
