<template>
  <eden-confirm-dialog
    title="Fund wallet"
    button-text="Confirm"
    button-type="success"
    :show.sync="shouldShow"
    @confirm="fund"
  >
    <p class="head-text font-lg">Fund employee’s wallet</p>
    <p class="font-md mt-2">
      You are about to fund
      <span class="text-bold">{{
        employees.length
          ? `${employees.length} employees’`
          : `${customerName}’s`
      }}</span>
      wallet with a sum of
      <span class="text-bold">{{ formatPrice(localAmount) }}</span
      >.
    </p>
  </eden-confirm-dialog>
</template>
  
  <script>
import wallet from "@/requests/customers/corporate/wallet";
import corporate from "@/requests/customers/corporate";

export default {
  name: "EmployeeFundWalletConfirmation",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    amount: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    employees: {
      type: Array,
      default() {
        return [];
      },
    },
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      localAmount: "",
      company: [],
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    customerName() {
      return this.formatName(this.customer.name);
    },
    customerId() {
      return this.$route.params.id;
    },
  },
  mounted() {
    this.localAmount = this.amount;
    this.getCompany();
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    fund() {
      this.loading = true;
      const payload = {
        corporation_id: this.customerId,
        amount: this.localAmount,
        employees: this.employees.length ? this.employees : [this.customer.id],
      };
      wallet
        .topUp(payload)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.getCompany().then(() => {
              this.$emit("wallet-updated", this.company);
            });
            this.loading = false;
            this.closeEvent();
            this.selectedEmployees = [];
            this.amount = 0;
          } else {
            this.loading = false;
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getCompany() {
      this.loading = true;
      return corporate
        .get(this.customerId)
        .then((response) => {
          this.company = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>
  
  <style lang="scss">
.font-lg {
  font-size: 1.125rem;
}
</style>
  