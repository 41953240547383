<template>
  <div>
    <el-drawer :visible.sync="shouldShow" direction="rtl" @close="closeEvent">
      <template slot="title">
        <span class="text-grey-primary">Generate Invoice</span>
      </template>
      <div v-loading="loading">
        <div class="el-drawer--content">
          <div class="el-drawer--content__body">
            <el-form :model="form" label-position="top" ref="form">
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Due date"
                    prop="due_date"
                    :rules="validateField()"
                  >
                    <el-date-picker
                      v-model="form.due_date"
                      type="date"
                      format="dd MMM, yyyy"
                      value-format="yyyy-MM-dd"
                      prefix-icon="eden-icon--calendar"
                    >
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Company name"
                    prop="company_name"
                    :rules="validateField()"
                  >
                    <el-input v-model="form.company_name" readonly />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Email address"
                    prop="contact_person_email_address"
                    :rules="validateField()"
                  >
                    <el-input
                      v-model="form.contact_person_email_address"
                      readonly
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Services"
                    prop="services"
                    :rules="validateField()"
                  >
                    <el-select
                      v-model="form.services"
                      placeholder="Select services"
                    >
                      <el-option
                        v-for="(service, index) in services"
                        :key="index"
                        :label="service"
                        :value="service.toLowerCase()"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Number of employees being serviced"
                    prop="number_of_active_employees"
                    :rules="validateFigure()"
                  >
                    <el-input
                      v-model.number="form.number_of_active_employees"
                      v-number
                      @change="calculateSubscriptionAmount"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Subscription amount"
                    prop="total_amount"
                    :rules="validateFigure()"
                  >
                    <el-input v-model.number="form.total_amount" v-number />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Total number of pauses"
                    prop="total_number_of_paused_days"
                  >
                    <el-input
                      v-model.number="form.total_number_of_paused_days"
                      v-number
                      @change="updateOutstandingAmount"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item
                    label="Amount owed due to pauses"
                    prop="outstanding_amount"
                  >
                    <el-input
                      v-model.number="form.outstanding_amount"
                      v-number
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="24">
                  <el-form-item label="Withholding tax value (optional)">
                    <el-input
                      v-model.number="witholding_tax_value_percent"
                      v-number
                      :maxlength="2"
                      @blur="validateTaxPercentage"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <div class="mt-2">
                <el-row type="flex">
                  <el-col :span="24">
                    <div class="is-flex is-justify-between">
                      <span>Apply a discount to this subscription</span>
                      <el-switch
                        v-model="discount_applicable"
                        :active-value="true"
                        active-text=""
                        :inactive-value="false"
                        @change="setDiscount"
                      >
                      </el-switch>
                    </div>
                  </el-col>
                </el-row>
                <template v-if="discount_applicable">
                  <el-row type="flex">
                    <el-col :span="24">
                      <el-form-item label="Discount type" prop="discount_type">
                        <el-radio-group
                          v-model="discount_type"
                          class="eden-custom--radio flex"
                        >
                          <el-radio label="percentage">Percentage</el-radio>
                          <el-radio label="fixed-amount">Fixed amount</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row v-if="discount_type !== ''" type="flex">
                    <el-col :span="24">
                      <el-form-item
                        label="Discount value"
                        prop="discount_value"
                      >
                        <el-input
                          v-if="discount_type === 'percentage'"
                          type="text"
                          v-model.number="discount_value"
                          v-number
                          :maxlength="2"
                          @blur="validatePercentage"
                        />
                        <el-input
                          v-else
                          type="text"
                          v-model.number="discount_value"
                          v-number
                        />
                      </el-form-item>
                      <span
                        v-if="showDiscountMessage"
                        class="discount-message fadeIn"
                        >Discount cannot be more than 49%</span
                      >
                    </el-col>
                  </el-row>
                </template>
              </div>
            </el-form>
          </div>
        </div>
        <div class="el-drawer--footer is-align-center">
          <el-button plain @click="closeEvent">Cancel</el-button>
          <el-button type="primary" @click="preview">Preview</el-button>
        </div>
      </div>
    </el-drawer>
    <customer-corporate-invoice-preview
      :show.sync="showCustomerCorporateInvoicePreview"
      :data="invoicePreviewData"
      @saved="closeEvent"
    />
  </div>
</template>

<script>
import CustomerCorporateInvoicePreview from "@/components/Customers/Corporate/Actions/Invoice/CustomerCorporateInvoicePreview";
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateInvoiceForm",
  components: { CustomerCorporateInvoicePreview },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading: false,
      form: {
        date_of_issue: "",
        start_date: "",
        due_date: "",
        company_name: "",
        contact_person_email_address: "",
        company_address: "",
        plan: {},
        services: [],
        unit_price: 0,
        number_of_active_employees: 0,
        total_amount: 0,
        total_number_of_paused_days: 0,
        outstanding_amount: 0,
        discount_amount: 0,
        amount_to_be_charged: 0,
        witholding_tax_value: 0,
      },
      witholding_tax_value_percent: 0,
      discount_applicable: false,
      discount_type: "",
      discount_value: 0,
      showDiscountMessage: false,
      showCustomerCorporateInvoicePreview: false,
      invoicePreviewData: {},
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    services() {
      return ["Cleaning", "Laundry", "Meal"];
    },
  },
  watch: {
    show() {
      if (this.show) {
        this.setForm();
      }
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
      this.discount_applicable = false;
      this.discount_type = "";
      this.discount_value = "";
      this.witholding_tax_value_percent = 0;
      this.$refs.form.resetFields();
    },
    setForm() {
      this.loading = true;
      this.form.date_of_issue = this.formatDate(new Date(), "y-m-d");
      this.form.start_date = this.data.start_date;
      this.form.due_date = this.data.next_charge_date;
      this.form.company_name = this.data.company_name;
      this.form.contact_person_email_address =
        this.data.contact_person_email_address;
      this.form.company_address = this.data.company_address;
      this.form.plan = JSON.stringify(this.data.plan);
      this.form.services = Object.keys(this.data.plan)[0];
      this.form.unit_price = this.pricing(this.data.plan);
      this.form.number_of_active_employees = this.data.active_employees_count;
      this.form.total_amount =
        this.form.unit_price * this.form.number_of_active_employees;
      this.getNumberOfPausedServices();
    },
    getNumberOfPausedServices() {
      const payload = {
        user_corporation_id: parseInt(this.customerId),
      };
      corporate
        .getPausedServices(payload)
        .then((response) => {
          if (response.data.status) {
            this.form.total_number_of_paused_days = response.data.data;
            this.form.outstanding_amount =
              this.getMealPrice() * this.form.total_number_of_paused_days;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
    updateOutstandingAmount() {
      this.form.outstanding_amount =
        this.getMealPrice() * this.form.total_number_of_paused_days;
    },
    calculateSubscriptionAmount() {
      if (this.form.number_of_active_employees) {
        this.form.total_amount =
          this.form.number_of_active_employees * this.form.unit_price;
      } else {
        this.form.total_amount = 0;
      }
    },
    getMealPrice() {
      let { meal } = this.data.plan;
      meal.frequency = "one-off";

      return this.pricing({ meal });
    },
    setDiscount() {
      if (!this.discount_applicable) {
        this.discount_type = "";
        this.discount_value = 0;
      }
    },
    validateTaxPercentage() {
      if (this.witholding_tax_value_percent >= 50) {
        this.witholding_tax_value_percent = 49;
        this.$message.error("Percent cannot be more than 49%");
      }
    },
    validatePercentage() {
      if (this.discount_value >= 50) {
        this.discount_value = 49;
        this.showDiscountMessage = true;

        setTimeout(() => {
          this.showDiscountMessage = false;
        }, 4000);
      }
    },
    calculateAmount() {
      const { outstanding_amount, total_amount } = this.form;

      // Calculate the discount amount
      if (this.discount_value) {
        this.form.discount_amount =
          this.discount_type === "percentage"
            ? (this.discount_value * total_amount) / 100
            : this.discount_value;
      } else {
        this.form.discount_amount = 0;
      }

      // Calculate witholding tax value amount
      if (this.witholding_tax_value_percent) {
        this.form.witholding_tax_value =
          (this.witholding_tax_value_percent * total_amount) / 100;
      } else {
        this.form.witholding_tax_value = 0;
      }

      // Set amount to be charged
      this.form.amount_to_be_charged =
        total_amount -
        this.form.discount_amount -
        this.form.witholding_tax_value -
        outstanding_amount;
    },
    preview() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        this.calculateAmount();

        this.invoicePreviewData = {
          ...this.form,
          witholding_tax_value_percent: this.witholding_tax_value_percent,
          discount_type: this.discount_type,
          discount_value: this.discount_value,
        };

        this.showCustomerCorporateInvoicePreview = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.heading {
  margin: 20px 0 10px;

  p {
    font-size: 1rem;
    font-weight: 500;
    color: #21312a;
  }
}

.eden-plan--summary {
  width: 100%;
  background: rgba(0, 77, 41, 0.04);
  border-radius: 8px;
  padding: 20px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  span {
    color: #798b83;
    width: 50%;
  }

  p {
    color: #21312a;
    font-size: 1rem;
  }
}

.eden-customer-service {
  margin-bottom: 40px;
}
</style>
