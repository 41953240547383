<template>
  <el-dialog
    title="Link Employees(s)"
    :visible.sync="setShow"
    width="30%"
    append-to-body
    @close="closeEvent"
  >
    <el-form :model="form" label-position="top" ref="form">
      <el-form-item label="Employee(s)" prop="customer_ids">
        <el-select
          v-model="form.customer_ids"
          filterable
          multiple
          remote
          :remote-method="debounce"
          :loading="fetching"
          placeholder="Select employee(s)"
        >
          <el-option
            v-for="customer in customers"
            :key="customer.id"
            :label="formatName(customer.name)"
            :value="customer.id"
          >
            <span style="float: left; max-width: 100px">{{
              formatName(customer.name)
            }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              customer.email.toLowerCase()
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button plain @click="closeEvent">Cancel</el-button>
      <el-button
        :disabled="!form.customer_ids.length"
        type="primary"
        @click="linkEmployees"
        :loading="linking"
        >Link</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import individual from "@/requests/customers/individual";
import corporate from "@/requests/customers/corporate";

export default {
  name: "CustomerCorporateEmployeesLink",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetching: false,
      form: {
        customer_ids: [],
        user_corporation_id: parseInt(this.$route.params.id),
      },
      customers: [],
      linking: false,
    };
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
      this.$refs.form.resetFields();
    },
    debounce(query) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getCustomersList(query);
      }, 700);
    },
    getCustomersList(query) {
      if (query !== "") {
        this.fetching = true;
        individual
          .search(query)
          .then((response) => {
            if (response.data.status) {
              if (this.allowAccessFor(["superadmin"])) {
                this.customers = response.data.data.customers;
              } else {
                this.customers = response.data.data.customers.filter(
                  (customer) => {
                    return (
                      (customer.customer_persona_name === null ||
                        customer.customer_persona_name.toLowerCase() ===
                          "regular") &&
                      customer.user_corporation_id === null &&
                      !customer.one_time_customer
                    );
                  },
                );
              }
              this.fetching = false;
            }
          })
          .catch();
      } else {
        this.customers = [];
      }
    },
    linkEmployees() {
      this.linking = true;
      corporate
        .linkEmployees(this.form)
        .then((response) => {
          if (response.data.status) {
            this.linking = false;
            this.$message.success(response.data.message);
            this.$emit("success");
            this.closeEvent();
          } else {
            this.$message.error(response.data.message);
            this.linking = false;
          }
        })
        .catch((error) => {
          this.linking = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
