<template>
  <div>
    <el-dialog
      :visible.sync="shouldShow"
      title="Invoice Preview"
      width="50%"
      class="show-margin-top"
      @close="closeEvent"
    >
      <customer-corporate-invoice :data="data" />
      <div class="is-flex is-justify-center mt-2">
        <el-button plain @click="shouldShow = false"
          >Continue editing</el-button
        >
        <el-button type="primary" :loading="saving" @click="save"
          >Save and Download</el-button
        >
      </div>
    </el-dialog>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :paginate-elements-by-height="1400"
      :filename="fileName"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="edenInvoice"
    >
      <section slot="pdf-content">
        <customer-corporate-invoice :data="data" />
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import corporate from "@/requests/customers/corporate";
import VueHtml2pdf from "vue-html2pdf";
import CustomerCorporateInvoice from "@/components/Customers/Corporate/Actions/Invoice/CustomerCorporateInvoice";

export default {
  name: "CustomerCorporateInvoicePreview",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    CustomerCorporateInvoice,
    VueHtml2pdf,
  },
  data() {
    return {
      saving: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    fileName() {
      return `Invoice_For_${this.data.company_name}`;
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
    save() {
      this.saving = true;

      corporate
        .generateInvoice(this.data)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.saving = false;
            this.closeEvent();
            this.$emit("saved");
            this.download();
          } else {
            this.$message.error(response.data.message);
            this.saving = false;
          }
        })
        .catch((error) => {
          this.saving = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    download() {
      this.$refs.edenInvoice.generatePdf();
    },
  },
};
</script>

<style lang="scss" scoped></style>
